import * as React from "react"

import Layout from "../components/layout"
import { SEO } from "../components/seo";

const NotFoundPage = () => (
  <Layout>
    <section className="container mx-auto py-20">
    <div className="grid grid-cols-2 bg-black py-20 px-10">
      <div className="title self-center px-4 py-10 text-gray-100">
        <h1 className="text-7xl font-bold">404: Cette page n'existe pas</h1>
        <p className="text-xl">
          Vous êtes perdu ?
        </p>
      </div>
      <div className="svg-container">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-600 64 1920 1300">
          <path
            fill="#000000"
            d="M-600.001 61.973A2.025 2.025 0 0 0-602.027 64l.002 1300c0 1.12.906 2.026 2.025 2.026l1920 .001a2.025 2.025 0 0 0 2.026-2.025V64a2.025 2.025 0 0 0-2.026-2.026H-600.001Z"
          />
          <path
            fill="#f05621"
            d="M470.573 465.199Q327.99 471.672 235.17 568.307q-92.82 96.635-93.547 239.363 1.07 46.482 13.79 89.003l15.223 41.139q17.933 39.952 45.068 73.591 84.668 104.053 222.774 123.068 22.98 2.016 44.955 1.574 116.265-2.342 204.394-73.508Q791.88 977.87 810.896 839.763l2.147-45.616q-6.473-142.583-103.108-235.402-96.635-92.82-239.362-93.546Z"
          />
          <g fill="#a53813" fillOpacity=".271">
            <path d="m413.974 795.3-.5 9.608q-3.193 15.673-4.946 18.14-2.344 5.63-5.617 10.703-6.546 10.146-16.805 18.066-5.165 3.958-10.763 6.903-14.372 6.478-17.766 6.558-12.492 2.854-26.713 1.658-6.792-.894-12.998-2.672-3.469-.337-17.297-7.325-10.651-6.21-18.957-15.956-18.577-23.607-15.984-49.485 1.112-12.882 6.247-23.843 2.567-5.481 6.14-10.482 1.215-2.858 12.864-13.807 19.396-16.206 47.458-17.402 14.043.077 26.061 3.984 6.009 1.953 11.511 4.865 13.313 8.115 15.368 10.888 4.743 4.347 8.499 9.212 8.525 12.614 9.046 15.758 4.55 11.28 5.152 24.629" />
            <path d="m404.002 798.827-.65 8.258q-4.66 25.429-24.629 39.75-7.92 6.212-21.848 10.355-9.088 2.441-23.348 1.651-5.748-.409-14.593-3.01-.83-.013-7.096-2.986-11.712-5.35-19.624-14.06-3.168-3.183-6.389-8.293-2.055-2.277-6.916-14.101-3.257-9.51-2.904-19.31 1.095-25.396 19.121-42.03 18.698-17.489 45.36-17.63 25.005.438 43.884 16.876 9.037 8.092 15.264 21.594 3.952 9.885 4.368 22.936" />
          </g>
          <g fill="#a53813" fillOpacity=".271">
            <path d="m612.539 1016.376-.5 9.608q-3.194 15.674-4.946 18.14-2.345 5.63-5.618 10.703-6.546 10.146-16.804 18.066-5.166 3.958-10.763 6.903-14.373 6.478-17.766 6.558-12.492 2.854-26.713 1.658-6.793-.894-12.998-2.672-3.47-.337-17.297-7.325-10.652-6.21-18.957-15.955-18.578-23.607-15.984-49.486 1.112-12.881 6.246-23.843 2.567-5.481 6.14-10.482 1.216-2.857 12.864-13.807 19.397-16.206 47.458-17.402 14.044.077 26.061 3.984 6.01 1.953 11.512 4.865 13.313 8.116 15.368 10.888 4.743 4.348 8.498 9.212 8.526 12.615 9.046 15.758 4.55 11.28 5.153 24.629" />
            <path d="m602.566 1019.903-.65 8.258q-4.66 25.429-24.629 39.75-7.919 6.212-21.848 10.355-9.088 2.441-23.347 1.652-5.748-.41-14.593-3.011-.83-.013-7.096-2.986-11.712-5.349-19.624-14.06-3.169-3.183-6.39-8.293-2.055-2.277-6.915-14.101-3.257-9.51-2.905-19.31 1.095-25.396 19.121-42.03 18.699-17.488 45.36-17.63 25.005.439 43.884 16.876 9.038 8.092 15.264 21.594 3.952 9.885 4.368 22.936" />
          </g>
          <g fill="#a53813" fillOpacity=".271">
            <path d="m796.159 711.074-.5 9.608q-3.194 15.673-4.946 18.14-2.345 5.63-5.618 10.702-6.546 10.147-16.805 18.067-5.165 3.958-10.762 6.903-14.373 6.477-17.766 6.558-12.492 2.854-26.713 1.658-6.793-.894-12.998-2.673-3.47-.336-17.297-7.324-10.652-6.21-18.957-15.956-18.577-23.607-15.984-49.485 1.112-12.882 6.246-23.844 2.567-5.48 6.14-10.481 1.216-2.858 12.864-13.808 19.396-16.206 47.458-17.4 14.044.076 26.061 3.983 6.01 1.953 11.512 4.865 13.313 8.115 15.367 10.888 4.743 4.347 8.499 9.212 8.526 12.614 9.046 15.757 4.55 11.28 5.153 24.63" />
            <path d="m786.186 714.6-.65 8.26q-4.66 25.427-24.628 39.748-7.92 6.213-21.849 10.355-9.088 2.442-23.347 1.652-5.748-.409-14.593-3.01-.83-.013-7.096-2.987-11.712-5.348-19.624-14.06-3.169-3.183-6.39-8.292-2.055-2.278-6.915-14.101-3.257-9.51-2.905-19.31 1.095-25.396 19.121-42.03 18.699-17.489 45.36-17.631 25.005.44 43.884 16.877 9.038 8.091 15.264 21.594 3.952 9.884 4.368 22.936" />
          </g>
          <g fill="#a53813" fillOpacity=".271">
            <path d="m566.891 559.024-.5 9.608q-3.193 15.673-4.945 18.14-2.345 5.63-5.618 10.702-6.546 10.147-16.805 18.067-5.165 3.958-10.763 6.903-14.372 6.478-17.765 6.558-12.493 2.854-26.714 1.658-6.792-.894-12.997-2.673-3.47-.336-17.297-7.324-10.652-6.21-18.957-15.956-18.578-23.607-15.984-49.485 1.112-12.882 6.246-23.843 2.567-5.481 6.14-10.482 1.215-2.858 12.864-13.807 19.396-16.207 47.458-17.402 14.043.077 26.061 3.984 6.009 1.953 11.511 4.865 13.313 8.115 15.368 10.888 4.743 4.347 8.499 9.212 8.525 12.614 9.046 15.758 4.55 11.28 5.152 24.629" />
            <path d="m556.919 562.55-.65 8.26q-4.66 25.427-24.629 39.748-7.92 6.213-21.848 10.355-9.088 2.442-23.348 1.652-5.748-.409-14.593-3.01-.83-.013-7.096-2.986-11.711-5.35-19.623-14.06-3.17-3.184-6.39-8.293-2.055-2.278-6.916-14.101-3.257-9.51-2.904-19.31 1.095-25.396 19.121-42.03 18.698-17.489 45.36-17.631 25.005.44 43.884 16.877 9.037 8.091 15.264 21.594 3.952 9.884 4.368 22.936" />
          </g>
          <path
            fill="#a53813"
            fillOpacity=".404"
            d="M720.002 568.812q-116.225 55.72-121.255 56.673-78.803 34.571-136.151 39.501-6.946.487-13.817.625-19.4.39-38.212-1.995-11.341-1.331-23.076-3.376l-44.455-8.15q-18.055-2.81-28.522-3.391-6.028-.893-14.496-.723-15.288.308-38.53 4.084-28.656 4.663-76.696 25.974-13.225 6.274-24.364 11.963-2.513 7.388-4.692 14.945 45.174-16.833 52.071-18.443 29.071-9.49 56.617-13.4 8.63-1.463 20.778-1.707 8.54-.172 18.82.258 8.802.623 20.573 2.463 74.643 14.252 82.507 14.345 20.77 2.65 42.223 2.217 7.142-.144 14.36-.629 63.834-5.565 146.061-42 72.526-31.88 125.67-62.101-7.353-8.758-15.406-17.134ZM812.835 790.152q-123.77 63.88-129.56 65.16-96.75 45.976-167.92 54.263-8.624.867-17.168 1.297-5.078.256-10.127.357-18.943.382-37.493-1.403-14.161-1.231-28.84-3.335l-55.618-8.47q-22.57-2.82-35.616-3.148-5.765-.678-13.284-.526-2.307.046-4.78.17-19.01.958-47.788 6.53-35.479 6.877-94.452 35.198l-.112.058.56 1.509q3.586 7.988 7.54 15.724 16.616-6.468 20.967-7.623 35.815-12.9 69.942-18.799 10.682-2.144 25.789-2.905 4.683-.236 9.792-.339 6.475-.13 13.633-.047 10.977.445 25.69 2.292 93.41 14.93 103.198 14.75 20.503 1.988 41.464 1.566 5.562-.112 11.156-.394 8.88-.447 17.843-1.322 79.216-9.322 180.157-57.744 62.901-29.945 113.799-58.498h.003v.002l-.003-.001h.008v.001l1.428-30.328q-.09-2.002-.2-3.995ZM697.869 1054.063q-1.32.55-1.808.658-96.75 45.975-167.92 54.263-8.624.867-17.168 1.297-5.078.256-10.127.357-18.943.382-37.494-1.403-14.16-1.231-28.839-3.335l-55.619-8.47q-22.57-2.82-35.615-3.148-5.765-.678-13.284-.526-2.307.046-4.78.17-4.927.249-10.511.807 55.326 30.314 123.775 39.738 22.98 2.016 44.955 1.574 116.265-2.342 204.394-73.508 5.127-4.172 10.048-8.474ZM470.573 465.199q-51.72 2.348-96.892 16.559 21.976 3.843 26.048 3.891 20.77 2.65 42.223 2.217 7.142-.144 14.36-.629 36.603-3.191 79.254-16.532-31.202-5.334-64.993-5.506Z"
          />
          <path
            fill="#ffce54"
            d="M28.229 683.272c.35-3.647.83-8.293 1.457-13.14 2.347-18.282 4.557-24.127 5.673-25.986 1.667-2.793 4.051-4.992 6.893-6.367l.983-.476 8.66-14.667 17.942 12.565-8.21 13.074.042 1.12c.137 3.153-.678 6.295-2.343 9.094-1.118 1.852-5.215 6.572-20.21 17.28a437.9 437.9 0 0 1-10.887 7.503Z"
          />
          <path
            fill="#f6bb42"
            d="m50.778 617.448-10.095 17.085c-3.339 1.615-6.313 4.246-8.42 7.766-5.38 8.995-8.279 48.061-8.279 48.061s33.048-21.022 38.437-30.024c2.098-3.513 3.013-7.384 2.855-11.095l9.433-15.025-23.931-16.768Zm8.392 27.956-1.191 1.905.094 2.241c.102 2.448-.537 4.901-1.844 7.086-.43.72-3.387 4.896-19.186 16.183a385.352 385.352 0 0 1-4.427 3.105c.198-1.73.412-3.53.65-5.36 2.473-19.263 4.75-23.843 5.19-24.565 1.306-2.19 3.167-3.908 5.367-4.968l1.966-.952 1.11-1.879 6.117-10.37 11.938 8.359-5.784 9.215Z"
          />
          <path
            fill="#ff000a"
            d="M82.489 645.269a153.197 153.197 0 0 0 5.14-4.393l32.756-38.552a225.69 225.69 0 0 0 3.663-5.883 238.779 238.779 0 0 0 2.022-3.467c4.419 18.23 1.084 40.226-10.768 60.046-3.953 6.6-8.556 12.478-13.62 17.567l-.236.234a3.609 3.609 0 0 1-4.29.44 3.572 3.572 0 0 1-1.12-1.072s-.216-.334-.286-.485l-13.716-24.07c.156-.121.3-.244.455-.365ZM62.433 554.892a201.774 201.774 0 0 0-5.544 9.434c-6.745 2.743-18.488 47.1-18.488 47.1a165.196 165.196 0 0 0-1.44 6.607c-.04.19-.068.378-.108.568l-27.7-.7a7.086 7.086 0 0 1-.562-.023 3.556 3.556 0 0 1-1.476-.486 3.605 3.605 0 0 1-1.636-3.99s.075-.263.096-.312c2.084-6.868 5.082-13.702 9.033-20.307 11.862-19.828 29.666-33.168 47.825-37.891Z"
          />
          <path
            fill="#fff"
            d="M59.037 556.018c-14.859 24.844-23.472 51.12-25.928 75.097a3.598 3.598 0 0 0 1.742 3.41l34.094 20.404a3.627 3.627 0 0 0 3.832-.084c19.97-13.503 39.045-33.512 53.904-58.356 23.095-38.592 31.053-80.152 24.371-112.208-.196-.955-.797-1.912-1.698-2.455-.911-.54-2.033-.619-2.978-.338-31.404 9.27-64.254 35.941-87.339 74.53Z"
          />
          <path
            fill="#434a54"
            d="M105.808 574.475a14.33 14.33 0 0 1-10.919-1.612 14.35 14.35 0 0 1-6.585-8.864 14.35 14.35 0 0 1 1.61-10.924 14.345 14.345 0 0 1 8.86-6.582 14.37 14.37 0 0 1 10.928 1.608c6.825 4.087 9.057 12.964 4.974 19.788-1.985 3.305-5.13 5.646-8.858 6.583l-.01.003ZM83.591 611.611c-3.737.94-7.614.37-10.918-1.611a14.335 14.335 0 0 1-6.584-8.86 14.362 14.362 0 0 1 1.61-10.928 14.328 14.328 0 0 1 8.858-6.583 14.373 14.373 0 0 1 10.93 1.609c6.824 4.087 9.056 12.964 4.974 19.787a14.348 14.348 0 0 1-8.87 6.586Z"
          />
          <path
            fill="#40beae"
            d="M111.556 545.01a17.947 17.947 0 0 0-13.662-2.014 17.94 17.94 0 0 0-11.075 8.232 17.943 17.943 0 0 0-2.016 13.651c1.175 4.673 4.106 8.603 8.233 11.08a17.934 17.934 0 0 0 13.65 2.011 17.938 17.938 0 0 0 11.087-8.229c5.105-8.532 2.316-19.627-6.217-24.732Zm.024 21.026a10.8 10.8 0 0 1-6.651 4.942c-2.68.674-5.629.323-8.187-1.212-5.127-3.067-6.798-9.71-3.73-14.838a10.77 10.77 0 0 1 6.641-4.934 10.791 10.791 0 0 1 8.197 1.204c5.126 3.067 6.797 9.711 3.73 14.838ZM75.68 580.138a17.917 17.917 0 0 0-11.077 8.227 17.902 17.902 0 0 0-2.015 13.657 17.908 17.908 0 0 0 8.232 11.075c4.137 2.47 8.98 3.19 13.652 2.016a17.947 17.947 0 0 0 11.086-8.235c5.104-8.532 2.315-19.628-6.218-24.732a17.941 17.941 0 0 0-13.66-2.008Zm13.684 23.035a10.778 10.778 0 0 1-6.651 4.942c-2.68.673-5.628.328-8.187-1.212-5.128-3.067-6.798-9.71-3.73-14.838a10.768 10.768 0 0 1 6.641-4.934 10.794 10.794 0 0 1 8.197 1.204c5.127 3.067 6.797 9.71 3.73 14.838Z"
          />
          <path
            fill="#ff000a"
            d="m151.34 530.985-46.245-27.66c13.408-10.331 27.49-17.764 41.28-21.837.946-.28 2.068-.203 2.979.338.9.543 1.502 1.5 1.698 2.455 2.94 14.087 3.045 30.006.289 46.704Z"
          />
          <path
            fill="#117bbd"
            d="M194.238 1289.266h.02l.096.001h.399l.024.001h.479q.082 0 .08-.001h-1.098"
          />
          <g fill="#fff">
            <path d="m-1.96 433.257-5.554 21.188-5.553 21.188L.04 463.501l15.755 8.128-17.755-38.372" />
            <path d="m-16.297 449.763-2.594.213-2.594.212L.802 463.402 19.59 446.75l-35.887 3.013" />
          </g>
          <g fill="#fff">
            <path d="m-259.938 725.321-5.554 21.188-5.553 21.189 13.107-12.133 15.755 8.128-17.755-38.372" />
            <path d="m-274.275 741.828-2.594.212-2.594.213 22.287 13.214 18.788-16.652-35.887 3.013" />
          </g>
          <g fill="#fff">
            <path d="m-178.138 945.664-5.553 21.188-5.554 21.189 13.107-12.133 15.756 8.128-17.756-38.372" />
            <path d="m-192.475 962.17-2.594.213-2.594.212 22.288 13.215 18.788-16.653-35.888 3.013" />
          </g>
          <g fill="#fff">
            <path d="m-336.379 1168.027-5.553 21.188-5.553 21.189 13.107-12.133 15.755 8.128-17.756-38.372" />
            <path d="m-350.716 1184.534-2.594.212-2.594.212 22.288 13.215 18.788-16.652-35.888 3.013" />
          </g>
          <g fill="#fff">
            <path d="m333.918 381.086-5.553 21.188-5.554 21.189 13.107-12.133 15.755 8.128-17.755-38.372" />
            <path d="m319.58 397.593-2.593.212-2.594.212 22.288 13.215 18.788-16.653-35.888 3.014" />
          </g>
          <g fill="#fff">
            <path d="m1106.29 669.09-7.364 12.915-7.363 12.916 10.724-5.652 8.902 8.097-4.9-28.276" />
            <path d="m1093.99 677.393-1.738-.316-1.738-.317 12.29 12.579 15.244-7.617-24.058-4.329" />
          </g>
          <g fill="#fff">
            <path d="m885.848 815.252-7.363 12.916-7.363 12.916 10.724-5.652 8.902 8.096-4.9-28.276" />
            <path d="m873.549 823.555-1.738-.316-1.738-.317 12.29 12.58 15.243-7.618-24.057-4.329" />
          </g>
          <g fill="#fff">
            <path d="m900.77 974.082-7.363 12.916-7.364 12.915 10.725-5.652 8.901 8.097-4.9-28.276" />
            <path d="m888.47 982.385-1.738-.316-1.738-.317 12.291 12.58q7.621-3.81 15.243-7.618l-24.058-4.329" />
          </g>
          <g fill="#fff">
            <path d="m757.962 1092.068-7.364 12.916-7.363 12.916 10.725-5.652 8.901 8.097-4.9-28.277" />
            <path d="m745.662 1100.372-1.738-.317-1.738-.316 12.29 12.579 15.244-7.617-24.058-4.33" />
          </g>
          <g fill="#fff">
            <path d="m889.332 580.36-7.363 12.917-7.363 12.915 10.724-5.652 8.902 8.097-4.9-28.276" />
            <path d="m877.033 588.664-1.738-.316-1.738-.317 12.29 12.58 15.243-7.618-24.057-4.329" />
          </g>
          <g fill="#fff">
            <path d="m705.517 362.396-7.363 12.915-7.363 12.916 10.724-5.652 8.902 8.097-4.9-28.276" />
            <path d="m693.218 370.699-1.738-.317-1.738-.316 12.29 12.579 15.243-7.617-24.057-4.33" />
          </g>
          <g fill="#fff">
            <path d="m244.601 170.153-7.363 12.916-7.363 12.916 10.725-5.652 8.901 8.096-4.9-28.276" />
            <path d="m232.302 178.456-1.738-.316-1.738-.317 12.29 12.58 15.244-7.618-24.058-4.329" />
          </g>
          <g fill="#fff">
            <path d="m633.665 345.263-5.554 21.189-5.553 21.188 13.107-12.133 15.755 8.129-17.755-38.373" />
            <path d="m619.327 361.77-2.593.212-2.594.213 22.287 13.214 18.788-16.652-35.888 3.013" />
          </g>
          <g fill="#fff">
            <path d="m-327.641 378.899-7.363 12.916-7.363 12.916 10.724-5.652 8.902 8.097-4.9-28.277" />
            <path d="m-339.94 387.202-1.738-.316-1.738-.316 12.29 12.578 15.243-7.617-24.057-4.329" />
          </g>
          <g fill="#fff">
            <path d="m-523.628 523.282-7.363 12.916-7.363 12.916 10.725-5.652 8.901 8.096-4.9-28.276" />
            <path d="m-535.927 531.585-1.738-.316-1.738-.317 12.29 12.58 15.244-7.618-24.058-4.329" />
          </g>
          <g fill="#fff">
            <path d="m-516.553 285.82-7.363 12.916-7.363 12.916 10.724-5.652 8.902 8.097-4.9-28.276" />
            <path d="m-528.852 294.124-1.738-.316-1.738-.317 12.29 12.579 15.243-7.617-24.057-4.329" />
          </g>
          <g fill="#fff">
            <path d="m960.027 680.565 10.848-19.029 10.848-19.029-15.8 8.327-13.115-11.929 7.219 41.66" />
            <path d="m978.147 668.332 2.56.466 2.561.466-18.107-18.532q-11.23 5.61-22.458 11.222l35.444 6.378" />
          </g>
          <g fill="#fff">
            <path d="m938.042 446.56 10.848-19.03 10.849-19.029-15.801 8.327-13.115-11.929 7.22 41.66" />
            <path d="m956.163 434.326 2.56.466 2.561.466-18.108-18.532-22.458 11.222 35.445 6.378" />
          </g>
          <g fill="#fff">
            <path d="m1148.443 272.728 10.848-19.029 10.848-19.029-15.8 8.328-13.115-11.93 7.219 41.66" />
            <path d="m1166.564 260.495 2.56.466 2.56.467-18.107-18.533-22.458 11.222 35.445 6.378" />
          </g>
          <g fill="#fff">
            <path d="m-374.202 381.275 10.455-10.57 10.455-10.57-11.822 2.684-6.502-10.125-2.586 28.581" />
            <path d="m-360.172 376.438 1.596.756 1.597.755-8.616-15.331-16.695 3.412 22.118 10.408" />
          </g>
          <g fill="#fff">
            <path d="m-123.442 297.148 10.455-10.57 10.455-10.57-11.822 2.683-6.503-10.125-2.585 28.582" />
            <path d="m-109.413 292.31 1.597.756 1.597.755-8.616-15.33-16.696 3.411 22.118 10.409" />
          </g>
          <g fill="#fff">
            <path d="m-96.747 139.868 10.455-10.57 10.455-10.57-11.822 2.684-6.503-10.125-2.585 28.58" />
            <path d="m-82.718 135.03 1.597.756 1.597.756-8.617-15.332-16.695 3.413 22.118 10.408" />
          </g>
          <g fill="#fff">
            <path d="m-187.602 523.134 10.455-10.57 10.455-10.57-11.822 2.683-6.502-10.125-2.586 28.582" />
            <path d="m-173.572 518.296 1.596.756 1.597.755-8.616-15.331-16.695 3.412 22.118 10.408" />
          </g>
          <g fill="#fff">
            <path d="m-66.464 781.246 10.455-10.57 10.455-10.57-11.822 2.684-6.502-10.125-2.586 28.582" />
            <path d="m-52.434 776.41 1.596.755 1.597.755-8.616-15.331L-74.552 766l22.118 10.408" />
          </g>
          <g fill="#fff">
            <path d="m-238.837 943.538 10.455-10.57 10.455-10.57-11.822 2.683-6.502-10.124-2.586 28.58" />
            <path d="m-224.808 938.7 1.597.756 1.597.756-8.616-15.332-16.695 3.413 22.117 10.408" />
          </g>
          <g fill="#fff">
            <path d="m-1.494 816.392 10.848-19.03 10.848-19.029-15.8 8.327-13.115-11.929 7.22 41.66" />
            <path d="m16.627 804.158 2.56.466 2.561.466L3.64 786.558l-22.458 11.222 35.445 6.378" />
          </g>
          <g fill="#fff">
            <path d="m935.762 1032.706 10.455-10.57 10.455-10.57-11.822 2.684-6.503-10.125-2.585 28.581" />
            <path d="m949.791 1027.87 1.597.755 1.597.755-8.616-15.331-16.696 3.412 22.118 10.408" />
          </g>
          <g fill="#fff">
            <path d="m1162.44 943.968 10.455-10.57 10.455-10.57-11.822 2.684-6.503-10.125-2.586 28.581" />
            <path d="m1176.469 939.131 1.596.755 1.597.756-8.616-15.331-16.695 3.412 22.118 10.408" />
          </g>
          <g fill="#fff">
            <path d="m1094.146 1171.507 10.455-10.57 10.455-10.57-11.822 2.684q-3.25-5.063-6.502-10.125l-2.586 28.581" />
            <path d="m1108.176 1166.67 1.596.755 1.597.756-8.616-15.332-16.695 3.413 22.118 10.408" />
          </g>
          <g fill="#fff">
            <path d="m55.614 932.03-21.126 5.785-21.126 5.785 17.06 5.284.839 17.709 24.353-34.563" />
            <path d="m34.15 927.867-1.48-2.14-1.481-2.14-.3 25.908 23.815 7.945-20.553-29.573" />
          </g>
          <g fill="#fff">
            <path d="m-94.308 1113.043-21.126 5.785-21.126 5.784 17.06 5.285.839 17.708 24.353-34.562" />
            <path d="m-115.771 1108.88-1.481-2.14-1.481-2.14-.3 25.908 23.815 7.945-20.553-29.573" />
          </g>
          <g fill="#fff">
            <path d="m-366 1087.184-21.127 5.784-21.126 5.785 17.061 5.285.838 17.708 24.354-34.562" />
            <path d="m-387.464 1083.02-1.48-2.14-1.482-2.14-.3 25.909 23.815 7.945-20.553-29.573" />
          </g>
          <g fill="#fff">
            <path d="m103.919 1127.547 14.382 3.77 14.381 3.768-8.235-8.896 5.517-10.694-26.045 12.052" />
            <path d="m115.123 1137.278.144 1.76.144 1.761 8.97-15.127-11.303-12.753 2.045 24.36" />
          </g>
          <g fill="#fff">
            <path d="m-122.816 1059.413 14.381 3.769 14.382 3.77-8.235-8.897 5.517-10.694-26.045 12.052" />
            <path d="m-111.612 1069.144.144 1.76.144 1.761 8.97-15.128-11.303-12.752 2.045 24.359" />
          </g>
          <g fill="#fff">
            <path d="m105.968 1201.385 21.903-.12 21.904-.12-15.112-9.52 3.774-17.322-32.47 27.082" />
            <path d="m125.623 1210.961.876 2.45.877 2.451 6.995-24.948-20.948-13.838 12.2 33.885" />
          </g>
          <path
            fillOpacity=".161"
            d="M843.79 803.502c-8.43 200.853-178.083 356.843-378.93 348.413-200.849-8.43-356.833-178.09-348.402-378.942 8.43-200.853 178.083-356.843 378.931-348.413 200.848 8.43 356.832 178.09 348.401 378.942m-50.686-2.128c7.255-172.86-126.99-318.872-299.842-326.127C320.409 467.992 174.4 602.24 167.145 775.1c-7.256 172.86 126.989 318.873 299.842 326.128 172.853 7.255 318.861-126.993 326.117-299.854Z"
          />
          <path d="M110.407 201.876Z" />
        </svg>
      </div>
    </div>
    </section>
  </Layout>
)

export default NotFoundPage

export const Head = () => (
  <SEO title="404" />
)
