import React from "react"
import { useSiteMetadata } from "../hooks/use-site-metadata"

export const SEO = ({
  title,
  description,
  pathname,
  children,
  ogImage,
  locale,
  canonical,
}) => {
  const {
    title: defaultTitle,
    description: defaultDescription,
    siteUrl,
    twitterUsername,
  } = useSiteMetadata()

  const seo = {
    title: `${title} | LHSL.fr` || defaultTitle,
    description: description || defaultDescription,
    image: ogImage || `${siteUrl}/lhsl-logo-officiel.png`,
    locale: locale || "fr",
    url: `${siteUrl}${pathname || ``}`,
    twitterUsername,
  }

  return (
    <>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta name="og:title" content={seo.title} />
      <meta name="og:description" content={seo.description} />
      <meta name="og:image" content={seo.image} />
      <meta name="og:locale" content={seo.locale} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:url" content={seo.url} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
      <link rel="icon" href="/lhsl-icon.png" />
      {canonical && <link rel="canonical" href={canonical} />}
      {children}
    </>
  )
}
